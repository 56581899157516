
import useMarketplaceChain from "hooks/useMarketplaceChain";
import {Box, Flex ,Text} from "../primitives";
type TabValue = 'collections' | 'mints'
import { paths } from '@reservoir0x/reservoir-sdk'
import { ChainContext } from 'context/ChainContextProvider'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import {
    ComponentPropsWithoutRef,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useRouter } from "next/router";
import supportedChains from 'utils/chains'
import {
    useTrendingCollections,
    useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import {
    CollectionsSortingOption,
} from 'components/common/CollectionsTimeDropdown'
import {
    MintsSortingOption,
} from 'components/common/MintsPeriodDropdown'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'
import bg from "../../public/index/bgstarslarge.png"
import Image from "next/image";
import Link from "next/link";
import cat_1 from "../../public/index/teamgreen.png"
import cat_2 from "../../public/index/teamblue.png"
import cat_3 from "../../public/index/teamred.png"
import bgHeader from "../../public/res2.png"
import human from "../../public/newHome/human-still.png"
import robot from "../../public/newHome/robot-still.png"
import zombie from "../../public/newHome/zombie-still.png"
import alien from "../../public/newHome/alien-still.png"
import blue from "../../public/newHome/blue-still.png"
import dogg from "../../public/newHome/dogg-still.png"
import golden from "../../public/newHome/golden-still.png"
// import bgimg from "../../public/newHome/bg-header-avatar.jpg"
import leaf_1 from '../../public/newHome/leaf-1.png'
import leaf_2 from '../../public/newHome/leaf-2.png'
import leaf_3 from '../../public/newHome/leaf-3.png'
import leaf_4 from '../../public/newHome/leaf-4.png'
import leaf_5 from '../../public/newHome/leaf-5.png'
import multisnoop from '../../public/newHome/multisnoop-mobile.png'
import logo from "../../public/newHome/logo.png"


type Props = InferGetServerSidePropsType<typeof getServerSideProps>
const NFTBanner: NextPage = () => {
    const router = useRouter()
    const { routePrefix } = useMarketplaceChain()
    // not sure if there is a better way to fix this
    const { theme: nextTheme } = useTheme()
    const [theme, setTheme] = useState<string | null>(null)
    useEffect(() => {
        if (nextTheme) {
            setTheme(nextTheme)
        }
    }, [nextTheme])

    const isSSR = typeof window === 'undefined'
    const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

    const [tab, setTab] = useState<TabValue>('collections')
    const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('24h')

    const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')
    let [countCard,setCountCard] = useState(0)
    let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
        limit: 20,
        period: sortByPeriod,
        type: 'any',
    }

    const { chain, switchCurrentChain } = useContext(ChainContext)

    useEffect(() => {
        if (router.query.chain) {
            let chainIndex: number | undefined
            for (let i = 0; i < supportedChains.length; i++) {
                if (supportedChains[i].routePrefix == router.query.chain) {
                    chainIndex = supportedChains[i].id
                }
            }
            if (chainIndex !== -1 && chainIndex) {
                switchCurrentChain(chainIndex)
            }
        }
    }, [router.query])

    const {
        data: trendingCollections,
        isValidating: isTrendingCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'volume',
            period: sortByTime,
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const {
        data: featuredCollections,
        isValidating: isFeaturedCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'sales',
            period: '24h',
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const { data: trendingMints, isValidating: isTrendingMintsValidating } =
        useTrendingMints({ ...mintsQuery }, chain.id, {
            fallbackData: "",
            keepPreviousData: true,
        })
    let volumeKey: ComponentPropsWithoutRef<
        typeof CollectionRankingsTable
    >['volumeKey'] = '1day'

    switch (sortByTime) {
        case '30d':
            volumeKey = '30day'
            break
        case '7d':
            volumeKey = '7day'
            break
        case '24h':
            volumeKey = '1day'
            break
    }

    return (
        <>
          <div className="main-container" data-v-702f8c4b="" data-v-e2f4e356="">
            <div id="home-snoop-section" className="banner-top" data-v-702f8c4b>
              <div className="banner-top__infos" data-v-702f8c4b="">
               <Image src={logo}  alt="logo" data-v-702f8c4b="" />
              </div>
              <Flex align="center"  css={{
                    flexDirection:"column",
                    "@md":{
                        maxWidth:"1134px",
                        width:"61%",
                        flexDirection:"row",
                        // margin:"$6 auto",
                        justifyContent:"flex-end"
                    }
                }}>
                    <Link href={`/${routePrefix}/collections/trending`} style={{
                        padding: "10px 20px",
                        margin: "10px 40px 10px 10px",
                        fontSize: "1em",
                        fontFamily: "screen1",
                        color: "#ffffff",
                        backgroundColor: "green",
                        boxShadow: "8px 8px 0px 0px #444444",
                        cursor: "pointer",
                        outline: "none",
                    }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>EXPLORE</span>
                    </Link>
                    <Link href={`/${routePrefix}/explore`} style={{
                        padding: "10px 20px",
                        marginRight: "10px",
                        fontSize: "1em",
                        fontFamily: "screen1",
                        color: "#ffffff",
                        backgroundColor: "blue",
                        boxShadow: "8px 8px 0px 0px #444444",
                        cursor: "pointer",
                        outline: "none",

                    }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>COLLECTIONS</span>
                    </Link>
                </Flex>
              <div className="banner-top__avatars banner-top__avatars--play" data-v-702f8c4b>
                <div className="item" data-v-702f8c4b>
                    <Image src={human}  width={202} alt="logo" data-v-702f8c4b="" />
                </div>
                <div className="item" data-v-702f8c4b>
                    <Image src={blue}  width={202} alt="logo" data-v-702f8c4b="" />
                </div>
                <div className="item" data-v-702f8c4b>
                    <Image src={alien} width={202} alt="logo" data-v-702f8c4b="" />
                </div>
                <div className="item" data-v-702f8c4b>
                    <Image src={zombie} width={202}  alt="logo" data-v-702f8c4b="" />
                </div>
                <div className="item" data-v-702f8c4b>
                    <Image src={dogg}  width={202} alt="logo" data-v-702f8c4b="" />
                </div>
                <div className="item" data-v-702f8c4b>
                    <Image src={robot} width={202}  alt="logo" data-v-702f8c4b="" />
                </div>
                <div className="item" data-v-702f8c4b>
                    <Image src={golden} width={202}  alt="" data-v-702f8c4b="" />
                </div>
                <div className="banner-top__container">
                    <div className="banner-top__mobile">
                    <Image src={multisnoop}   alt="" data-v-702f8c4b="" />
                    </div>
                </div>
               </div>
               <div className="banner-top__cta" data-v-702f8c4b>
                 <h3 className="is-text-bold" data-v-702f8c4b>10K NEXT-GENERATION,</h3>
                 <h3 className="is-text-bold" data-v-702f8c4b="">METAVERSE AVATARS</h3>
                 <p data-v-702f8c4b="">
                    Unique, hand-crafted, and playable
                 </p>
                 
               </div>
           
            </div>
            <div className="banner-top__leafs" data-v-702f8c4b>
                <Image src={leaf_1}  alt="logo" data-v-702f8c4b="" />
                <Image src={leaf_2}  alt="logo" data-v-702f8c4b="" />
                <Image src={leaf_3}  alt="logo" data-v-702f8c4b="" />
                <Image src={leaf_4}  alt="logo" data-v-702f8c4b="" />
                <Image src={leaf_5}  alt="logo" data-v-702f8c4b="" />
            </div>

          </div>
        </>
        // <Box css={{
        //     width: "100%",
        //     // height: "100vh",
        //     background: `url(${bg.src}) center`,
        //     backgroundColor:"#000",
        //     backgroundSize: "cover",
        //     px: "$4"
        // }}>
        //     <Box css={{
        //         width: "100%",
        //         gap: 24,
        //         "@md": {
        //             // height: "100vh"
        //         }
        //     }}>
        //         <Flex align="center" justify="center">
        //             <Text style="h2" css={{
        //                 fontFamily: "'setbackt', courier, mono space",
        //                 color: "#88ee88",
        //                 textShadow: "4px 4px 0px #444",
        //                 textAlign: "center",
        //                 fontSize: "50px",
        //                 margin: "1em",
        //                 letterSpacing: "10px",
        //                 "@md": {
        //                     fontSize: "120px",
        //                 }
        //             }}>
        //                 SliceNFT
        //             </Text>
        //         </Flex>

        //         <Box css={{
        //             backgroundColor: "#fff",
        //             padding: "0.5em",
        //             boxShadow: "8px 8px 0px 0px #555555",
        //             overflowWrap: " break-word",
        //             width: "80%",
        //             margin: "0 auto"
        //         }}>

        //             <Text style="body1" css={{
        //                 color: "#4400bb",
        //                 fontSize: "1.5em",
        //                 fontFamily: 'pixelar, mono space, sans-serif',
        //                 textAlign: "left"
        //             }}>
        //                 MoonCats are an original NFT which launched in 2017 and helped pioneer <span style={{
        //                 color: "red"
        //             }}>on-chain generation,
        //                 fair distribution</span>, and <span style={{
        //                 color: "red"
        //             }}>user customization.</span> <Link
        //                 href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`}
        //                 style={{
        //                     color: "#00aa00"
        //                 }}>More about the project...</Link>
        //             </Text>
        //         </Box>
        //         <Flex align="center" justify="center" css={{
        //             marginTop: '$6',
        //             flexDirection:"column",
        //             "@md": {
        //                 flexDirection:"row"
        //             }
        //         }}>
        //             <Image src={cat_1} alt=""/>
        //             <Image src={cat_2} alt=""/>
        //             <Image src={cat_3} alt=""/>
        //         </Flex>

        //         <Flex align="center"  css={{
        //             flexDirection:"column",
        //             "@md":{
        //                 width:"40%",
        //                 flexDirection:"row",
        //                 margin:"$6 auto",
        //                 justifyContent:"space-around"
        //             }
        //         }}>
        //             <Link href={`/${routePrefix}/collections/trending`} style={{
        //                 padding: "10px 20px",
        //                 margin: "10px",
        //                 fontSize: "1em",
        //                 fontFamily: "screen1",
        //                 color: "#ffffff",
        //                 backgroundColor: "green",
        //                 boxShadow: "8px 8px 0px 0px #444444",
        //                 cursor: "pointer",
        //                 outline: "none",
        //             }}>
        //                 <span style={{
        //                     color: "#FFFFFF",
        //                     fontSize: '20px',
        //                 }}>EXPLORE</span>
        //             </Link>
        //             <Link href={`/${routePrefix}/explore`} style={{
        //                 padding: "10px 20px",
        //                 marginRight: "10px",
        //                 fontSize: "1em",
        //                 fontFamily: "screen1",
        //                 color: "#ffffff",
        //                 backgroundColor: "blue",
        //                 boxShadow: "8px 8px 0px 0px #444444",
        //                 cursor: "pointer",
        //                 outline: "none",

        //             }}>
        //                 <span style={{
        //                     color: "#FFFFFF",
        //                     fontSize: '20px',
        //                 }}>COLLECTIONS</span>
        //             </Link>
        //         </Flex>
        //     </Box>
        //     <Box css={{
        //         height:"200px"
        //     }}>

        //     </Box>
        // </Box>
    )
}

type TrendingCollectionsSchema =
    paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
    paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
    ssr: {
        trendingMints: TrendingMintsSchema
        trendingCollections: TrendingCollectionsSchema
        featuredCollections: TrendingCollectionsSchema
    }
}> = async ({params, res}) => {
    const chainPrefix = params?.chain || ''
    const reservoirBaseUrl = process.env.NEXT_PUBLIC_HOST_URL
// init BaseApi
// supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
// DefaultChain
// console.log("请求的路劲",reservoirBaseUrl)
    const headers: RequestInit = {
        headers: {
            'x-api-key': process.env.RESERVOIR_API_KEY || '',
        },
    }
    let trendingCollectionsQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            sortBy: 'volume',
        }

    const trendingCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    trendingCollectionsQuery,
    headers
)
let featuredCollectionQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        sortBy: 'sales',
    }

const featuredCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    featuredCollectionQuery,
    headers
)

let trendingMintsQuery: paths['/collections/trending-mints/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        type: 'any',
    }

const trendingMintsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending-mints/v1`,
    trendingMintsQuery,
    headers
)

const promises = await Promise.allSettled([
    trendingCollectionsPromise,
    featuredCollectionsPromise,
    trendingMintsPromise,
]).catch((e) => {
    console.error(e)
})

const trendingCollections: Props['ssr']['trendingCollections'] =
    promises?.[0].status === 'fulfilled' && promises[0].value.data
        ? (promises[0].value.data as Props['ssr']['trendingCollections'])
        : {}
const featuredCollections: Props['ssr']['featuredCollections'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
        ? (promises[1].value.data as Props['ssr']['featuredCollections'])
        : {}

const trendingMints: Props['ssr']['trendingMints'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
        ? (promises[1].value.data as Props['ssr']['trendingMints'])
        : {}

res.setHeader(
    'Cache-Control',
    'public, s-maxage=120, stale-while-revalidate=180'
)
return {
    props: { ssr: { trendingCollections, trendingMints, featuredCollections } },
}
}
export default NFTBanner